import React, { Component } from 'react';
import LinkView from '../view/LinkView';

class Link extends Component {

    constructor(props) {
        super(props);

        this.state = {
            link : this.props.link || ""
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( prevState.link !== this.state.link ) {
            this.props._fetchLink(this.state.link);
        }
    }

    _handleChange = (event) => {
        this.setState({
            ...this.state,
            link: event.target.value
        });
    }

    render(){
        return(
            <LinkView 
                _handleChange={this._handleChange}
                linkRecipe={this.state.link}
             />
        );

    }
}

export default Link;