import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    blocButtonValidRecipe: {
        position: 'relative'
    },
    buttonProgress: {
        color: 'rgba(0, 129, 255, 1)',
        position: 'absolute',
        top: '20px',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
}));

const ButtonSubmitRecipeView = (props) => {

    const classes = useStyles();
    let disabled = true;
    let textButton;

    if( props.isUpdate.status ) {
        textButton = 'modifier ma recette';
    }else {
        textButton = 'créer ma recette';
    }

    if( props.dataFormRecipe.title ) {
        disabled = false;
    }

    if (props.isLoadingSubmitRecipe) {
        disabled = true
    }

    return(
        <div className={classes.blocButtonValidRecipe}>
            <Button
                fullWidth
                variant="contained" 
                color="primary"
                disabled={disabled}
                onClick={props._submitFormRecipe}
                disableElevation
            >
                {textButton}
            </Button>
            {props.isLoadingCircular && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>

    );
}

export default ButtonSubmitRecipeView;