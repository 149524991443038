import React, { Component } from 'react';
import Client from '../../../Client';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { urlFormat } from '../../../utils/services';
import FormRecipeView from '../view/FormRecipeView'
import LinearProgressView from '../../../components/ProgressBar/view/LinearProgressView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';
import ErrorScript from '../../../components/ErrorScript/AppErrorScript';

class FormRecipe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            idTheme: props.match.params.id_theme,
            name: "",
            isBadRequest: false,
            isLoaded: true,
            isModal: false,
            isLoadingSubmitRecipe: false,
            isLoadingCircular: false,
            isUpdate: {
                id_recipe: "",
                status: false
            },
            dataFormRecipe: {
                picture: "",
                difficulty: 0,
                category: "",
                collection_category: [],
                /*existing_category: {
                    name_category: "",
                    id_category: ""
                },*/
                title: "",
                timeWorkingHour: "",
                timeWorkingMinute: "",
                timeCookingHour: "",
                timeCookingMinute: "",
                timeRestingHour: "",
                timeRestingMinute: "",
                share: 0,
                items: [],
                steps: [],
                note: "",
                addOrNotCourse: {
                    status: false,
                    id_course: ""
                },
                link: ""
            },
            isModalSubmit: {
                status: false,
                message: "",
                id_recipe: ""
            },
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			},
            errorScript: false,
        }

        this.client = new Client();
        
    }

    componentDidMount = () => {
        /* update recipe */
        if( this.props.match.params.hasOwnProperty('id_recipe') ) {
            
            this.client.getRecipe(this.props.match.params.id_recipe).then((res) => {
                
                this.setState({
                    ...this.state,
                    isLoaded: false,
                    idTheme: res.data.recipe.idTheme,
                    name: res.data.recipe.title,
                    isUpdate: {
                        id_recipe: this.props.match.params.id_recipe,
                        status: true,
                    },
                    dataFormRecipe: {
                        picture: res.data.recipe.picture,
                        difficulty: res.data.recipe.difficulty,
                        collection_category: res.data.recipe.collection_category,
                        category: "",
                        title: res.data.recipe.title,
                        timeWorkingHour: res.data.recipe.timeWorkingHour,
                        timeWorkingMinute: res.data.recipe.timeWorkingMinute,
                        timeCookingHour: res.data.recipe.timeCookingHour,
                        timeCookingMinute: res.data.recipe.timeCookingMinute,
                        timeRestingHour: res.data.recipe.timeRestingHour,
                        timeRestingMinute: res.data.recipe.timeRestingMinute,
                        share: res.data.recipe.share,
                        items: res.data.recipe.items,
                        steps: res.data.recipe.steps,
                        note: res.data.recipe.note,
                        addOrNotCourse: res.data.recipe.addOrNotCourse,
                        link: res.data.recipe.link
                    }
                })
                
            }).catch(error =>  {
                this.setState({
                    errorAuthAPI: {
                        status: true,
                        code_status: error.response.status,
                        data: error.response.data
                    }
                });
            });
            
        }

        /* create recipe */
        else {
            
            this.client.getTheme(this.props.match.params.id_theme).then((res) => {
                if(!res.data) {
                    this.setState({
                        isBadRequest: true
                    })
                }else {
                    this.setState({
                        name: res.data.name,
                        isLoaded: false,
                        idTheme: this.props.match.params.id_theme
                    })
                }
            }).catch(error =>  {
                this.setState({
                    errorAuthAPI: {
                        status: true,
                        code_status: error.response.status,
                        data: error.response.data
                    }
                });
            });
            
        }

    }

    /**
     * display Modal for Step
     */
    modalStep = () => {
        this.setState({
            ...this.state,
            isModalStep: !this.state.isModalStep
        });
    }

    /**
     * submit form recipe
     */
    _submitFormRecipe = (evt) => {
        evt.preventDefault();

        // loading
        this.setState({
            ...this.state,
            isLoadingSubmitRecipe: true,
            isLoadingCircular: true
        });

        var datasRecipe = this.state.dataFormRecipe;
        var theme = this.state.idTheme;
        datasRecipe.theme = theme

        // check if from update form or new create form
        if (this.state.isUpdate.status) {
            this.__updateRecipe(datasRecipe)
        } else {
            this.__createRecipe(datasRecipe)
        }
    }

    __updateRecipe = (datasRecipe) => {
        if (Array.isArray(datasRecipe.items) && Array.isArray(datasRecipe.steps)) {
            this.__fetchUpdateRecipe(datasRecipe, this.state.isUpdate.id_recipe)
        } else {
            this.setState({
                errorScript: true
            })
        }
    }

    __createRecipe = (datasRecipe) => {
        if (Array.isArray(datasRecipe.items) && Array.isArray(datasRecipe.steps)) {
            this.__fetchSetRecipe(datasRecipe)
            
        }else {
            this.setState({
                errorScript: true
            })
        }
    }

    __fetchSetRecipe = (datasRecipe) => {
        this.client.setRecipe(datasRecipe).then(res => {

            if( res.data.error === 1 ) {
                this.setState({
                    ...this.state,
                    error: true,
                    message: res.data.message
                })
            } else {
                this.setState({
                    ...this.state,
                    error: false,
                    isLoadingCircular: false,
                    isModalSubmit: {
                        status: true,
                        message: "RECIPE_CREATED_SUCCESSFULLY",
                        recipe: {
                            id_recipe: res.data.desc.id_recipe,
                            title_recipe: urlFormat(res.data.desc.title_recipe)
                        }
                    }
                });
            }
            
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
    }

    __fetchUpdateRecipe = (datasRecipe, id_recipe) => {
        this.client.updateRecipe(datasRecipe, id_recipe).then((res) => {
            if( res.data.error === 0 ) {
                this.setState({
                    ...this.state,
                    error: false,
                    isLoadingCircular: false,
                    isModalSubmit: {
                        status: true,
                        message: "UPDATED_RECIPE_SUCCESSFULLY",
                        recipe: {
                            id_recipe: res.data.desc.id_recipe,
                            title_recipe: urlFormat(res.data.desc.title_recipe)
                        }
                    }
                })
            }else {
                this.setState({
                    ...this.state,
                    error: true,
                    message: res.data.message
                })
            }
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
        
    }

    /**
     * retrieve difficulty
     */
    _fetchDifficulty = (difficulty) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                difficulty: difficulty
            }
        });
    }

    /**
     * retrieve title
     */
    _fetchTitle = (title) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                title: title
            }
        });
    }

    /**
     * retrieve time working hour
     */
    _fetchTimeWorkingHour = (time) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                timeWorkingHour: time
            }
        });
    }

    /**
     * retrieve time working minute
     */
    _fetchTimeWorkingMinute = (time) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                timeWorkingMinute: time
            }
        });
    }

    /**
     * retrieve time cooking hour
     */
    _fetchTimeCookingHour = (time) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                timeCookingHour: time
            }
        });
    }

    /**
     * retrieve time cooking minute
     */
    _fetchTimeCookingMinute = (time) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                timeCookingMinute: time
            }
        });
    }

    /**
     * retrieve time resting hour
     */
    _fetchTimeRestingHour = (time) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                timeRestingHour: time
            }
        });
    }

    /**
     * retrieve time resting minute
     */
    _fetchTimeRestingMinute = (time) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                timeRestingMinute: time
            }
        });
    }

    /**
     * retrieve share
     */
    _fetchShare = (share) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                share: share
            }
        });
    }

    /**
     * retrieve items
     */
    _fetchItems = (items) => {
        let isCourse = this.state.dataFormRecipe.addOrNotCourse.status;
        if (items.length === 0 ) isCourse = false;

        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                items,
                addOrNotCourse: {
                    status: isCourse,
                    id_course: ""
                } 
            }
        });
    }

    /**
     * retrieve steps
     */
    _fetchSteps = (steps) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                steps
            }
        });
    }

    /**
     * retrieve note
     */
    _fetchEditor = (note) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                note: note.target.value
            }
        });
    }

    /**
     * retrieve check course
     */
    _fetchCourse = (bool) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                addOrNotCourse: {
                    status: bool,
                    id_course: ""
                } 
            }
        });
    }

    /**
     * retrieve category
     */
    _fetchCategory = (category) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                collection_category: category,
            }
        })
    }

    /**
     * retrieve picture
     */
    _fetchPicture = (picture) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                picture: picture
            }
        })
    }

    /**
     * retrieve link
     */
    _fetchLink = (link) => {
        this.setState({
            ...this.state,
            dataFormRecipe: {
                ...this.state.dataFormRecipe,
                link: link
            }
        });
    }

    
    render(){
        if (this.state.errorAuthAPI.status) {

            return <DialogError error={this.state.errorAuthAPI} />

        }else if (this.state.errorScript) {

            return <ErrorScript code="01" />

        }else if(this.state.isBadRequest) {

            return <Redirect to='/creer-recette' />;

        }else if (this.state.isLoaded) {

			return(
				<LinearProgressView />
            );
            
		}else {

            return(
                    <FormRecipeView 
                        link={this.state.dataFormRecipe.link}
                        _fetchLink={this._fetchLink}
                        name={this.state.name}
                        _fetchPicture={this._fetchPicture}
                        picture={this.state.dataFormRecipe.picture}
                        _fetchDifficulty={this._fetchDifficulty}
                        difficulty={this.state.dataFormRecipe.difficulty}
                        _fetchTitle={this._fetchTitle}
                        title={this.state.dataFormRecipe.title}
                        idTheme={this.state.idTheme}
                        _fetchCategory={this._fetchCategory}
                        category={this.state.dataFormRecipe.collection_category}
                        _fetchTimeWorkingHour={this._fetchTimeWorkingHour}
                        _fetchTimeWorkingMinute={this._fetchTimeWorkingMinute}
                        timeWorkingHour={this.state.dataFormRecipe.timeWorkingHour}
                        timeWorkingMinute={this.state.dataFormRecipe.timeWorkingMinute}
                        _fetchTimeCookingHour={this._fetchTimeCookingHour}
                        _fetchTimeCookingMinute={this._fetchTimeCookingMinute}
                        timeCookingHour={this.state.dataFormRecipe.timeCookingHour}
                        timeCookingMinute={this.state.dataFormRecipe.timeCookingMinute}
                        _fetchTimeRestingHour={this._fetchTimeRestingHour}
                        _fetchTimeRestingMinute={this._fetchTimeRestingMinute}
                        timeRestingHour={this.state.dataFormRecipe.timeRestingHour}
                        timeRestingMinute={this.state.dataFormRecipe.timeRestingMinute}
                        _fetchShare={this._fetchShare}
                        share={this.state.dataFormRecipe.share}
                        _fetchItems={this._fetchItems}
                        _fetchCourse={this._fetchCourse}
                        items={this.state.dataFormRecipe.items}
                        addOrNotCourse={this.state.dataFormRecipe.addOrNotCourse}
                        _fetchSteps={this._fetchSteps}
                        steps={this.state.dataFormRecipe.steps}
                        _fetchEditor={this._fetchEditor}
                        note={this.state.dataFormRecipe.note}
                        dataFormRecipe={this.state.dataFormRecipe}
                        _submitFormRecipe={this._submitFormRecipe}
                        isUpdate={this.state.isUpdate}
                        isLoadingSubmitRecipe={this.state.isLoadingSubmitRecipe}
                        isLoadingCircular={this.state.isLoadingCircular}
                        isModalSubmit={this.state.isModalSubmit}
                    />
            );
        }

    }
}

FormRecipe = connect()(FormRecipe);

export default FormRecipe;