import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';

import Client from '../../../Client';

import MyRecipeView from '../view/MyRecipeView';

import LinearProgressView from '../../../components/ProgressBar/view/LinearProgressView';
import DialogError from '../../../components/ErrorAuthAPI/view/DialogError';

import { connect } from 'react-redux';
import { addCart, removeCart } from '../../../redux/actions/cart';

class MyRecipe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: true,
            recipe: "",
            isCourse: false,
            idCourse: "",
            isCopy: false,
            isShare: false,
            isSchedule: false,
            isRemoveSchedule: {
                status: false,
                schedules: []
            },
            isModal: {
                "status": false,
                "success": false,
                "redirection": false
            },
            snackBar: {
                open: false,
                status: "",
                desc: ""
            },
            isRedirect: false,
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			},
            isPicker: false,
            openSpeedDial: false
        }

        this.client = new Client();
    }

    componentDidMount = () => {
        this._getRecipe(this.props.match.params.id_recipe);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( prevProps.removeIdCourse !== this.props.removeIdCourse ) {
            if( this.props.removeIdCourse === this.state.idCourse ) {
                this.setState({
                    ...this.state,
                    isCourse: false,
                    idCourse: ""
                });
            }
        }

        if( prevState.isModal.success !== this.state.isModal.success ) {
            this.scheduleRedirection();
        }

        if( prevState.isModal.redirection !== this.state.isModal.redirection ) {
            this.sendRedirection();
        }
        
    }

    scheduleRedirection = () => {
        this.timer = setTimeout(this.renderRedirection, 1000);
    }

    sendRedirection = () => {
        this.timerRedirection = setTimeout(this.goRedirection, 2000);
    }

    renderRedirection = () => {
        this.setState({
            ...this.state,
            isModal: {
                "status": true,
                "success": true,
                "redirection": true
            }
        })
    }

    goRedirection = () => {
        this.setState({
            ...this.state,
            isRedirect: true
        });
    }

    _getRecipe = (id_recipe) => {
        this.client.getRecipe(id_recipe).then(res => {
            if (res.data) {
                // retrieve information recipe
                this.setState({
                    ...this.state,
                    isLoaded: false,
                    isCourse: res.data.recipe.addOrNotCourse.status,
                    idCourse: res.data.recipe.addOrNotCourse.id_course,
                    recipe: res.data.recipe
                })

            }else {
                // redirection
                this.setState({
                    ...this.state,
                    isRedirect: true
                })
            }
        }).catch(error =>  {
            
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
            
        });
    }

    _toogleFavorite = () => {
        this.client.setUpdateFavorite(this.state.recipe).then(res => {
            if( res.data.error === 0 ) {
                this.setState({
                    ...this.state,
                    recipe: {
                        ...this.state.recipe,
                        favorite: !this.state.recipe.favorite
                    }
                })
            }
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
    }

    _toogleCart = () => {
        if( this.state.isCourse ) {
            this.client.deleteCourse(this.state.idCourse).then(res => {
                if( res.data.error === 0 ) {
                    this.props.dispatch(removeCart(1));
                    this.setState({
                        ...this.state,
                        isCourse: false,
                        idCourse: ""
                    })
                }
            }).catch(error =>  {
                this.setState({
                    errorAuthAPI: {
                        status: true,
                        code_status: error.response.status,
                        data: error.response.data
                    }
                });
            });
        } else {
            let data = {
                id: this.state.recipe.id,
                idTheme: this.state.recipe.idTheme,
                title: this.state.recipe.title,
                items: this.state.recipe.items
            }
            this.client.addCourse(data).then(res => {
                if( res.data.error === 0 ) {
                    this.props.dispatch(addCart(1));
                    this.setState({
                        ...this.state,
                        isCourse: true,
                        idCourse: res.data.data.course_id
                    })
                } 
            }).catch(error =>  {
                this.setState({
                    errorAuthAPI: {
                        status: true,
                        code_status: error.response.status,
                        data: error.response.data
                    }
                });
            });
        }
    }

    _deleteRecipe = () => {
        this.setState({
            ...this.state,
            isModal: {
                "status": true,
                "success": false,
                "redirection": false
            }
        });
    }

    _toggleModal = () => {
        this.setState({
            ...this.state,
            isModal: {
                "status": !this.state.isModal,
                "success": false,
                "redirection": false
            }
        });
    }

    _confirmDeleteRecipe = () => {
        this.client.deleteRecipe(this.state.recipe.id).then(res => {
            if( res.data.error === 0 ) {
                if (this.state.isCourse) {
                    this.props.dispatch(removeCart(1));
                }

                this.setState({
                    ...this.state,
                    isModal: {
                        "status": true,
                        "success": true,
                        "redirection": false
                    }
                })
            }
        }).catch(error =>  {
            this.setState({
                errorAuthAPI: {
                    status: true,
                    code_status: error.response.status,
                    data: error.response.data
                }
            });
        });
    }

    _toggleCopyRecipe = () => {
        this.setState({
            ...this.state,
            isCopy: !this.state.isCopy
        })
    }

    _copiedStatus = (status) => {
        this.setState({
            ...this.state,
            isCopy: !this.state.isCopy,
            snackBar: {
                open: true,
                status: status.status,
                desc: status.desc
            }
        })
    }


    _toggleDatePickerRecipe = () => {
        this.setState({
            ...this.state,
            isPicker: !this.state.isPicker
        })
    }

    _handleCloseSnackBar = () => {
        this.setState({
            ...this.state,
            snackBar: {
                open: false,
                status: "",
                desc: ""
            }
        })
    }

    _toggleShareRecipe = () => {
        this.setState({
            ...this.state,
            isShare: !this.state.isShare
        })
    }

    _handleOnCloseSpeedDial = () => {
        this.setState({
            ...this.state,
            openSpeedDial: false
        })
    }

    _handleOnOpenSpeedDial = () => {
        this.setState({
            ...this.state,
            openSpeedDial: true
        })
    }

    render(){ 

        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else if( this.state.isLoaded ) {

            return <LinearProgressView />

        }else if(this.state.isRedirect) {

            return <Redirect to={`/mes-recettes/${this.state.recipe.idTheme}/${this.state.recipe.theme}`} />

        }else {

            return (
                <MyRecipeView
                    _deleteRecipe={this._deleteRecipe}
                    isCourse={this.state.isCourse}
                    recipe={this.state.recipe}
                    _toogleFavorite={this._toogleFavorite}
                    _toogleCart={this._toogleCart} 
                    _toggleModal={this._toggleModal}
                    _confirmDeleteRecipe={this._confirmDeleteRecipe}
                    _toggleCopyRecipe={this._toggleCopyRecipe}
                    message={this.state.isModal}
                    isModal={this.state.isModal.status}
                    isCopy={this.state.isCopy}
                    _copiedStatus={this._copiedStatus}
                    snackBar={this.state.snackBar}
                    _handleCloseSnackBar={this._handleCloseSnackBar}
                    _toggleShareRecipe={this._toggleShareRecipe}
                    isShare={this.state.isShare}
                    isSchedule={this.state.isSchedule}
                    isRemoveSchedule={this.state.isRemoveSchedule}
                    _toggleDatePickerRecipe={this._toggleDatePickerRecipe}
                    isPicker={this.state.isPicker}
                    openSpeedDial={this.state.openSpeedDial}
                    _handleOnCloseSpeedDial={this._handleOnCloseSpeedDial}
                    _handleOnOpenSpeedDial={this._handleOnOpenSpeedDial}
                />
            );
        }
    }
}

function mapsStateToProps(state) {
    return {
        totalCart: state.totalCart,
        addCart: state.addCart,
        removeIdCourse: state.removeIdCourse
    }
}

MyRecipe = connect(mapsStateToProps)(MyRecipe);

export default MyRecipe;