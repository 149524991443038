import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import ClientDatePicker from './client/ClientDatePicker';
import DialogDatePickerView from './view/DialogDatePickerView';
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FlashMessageComponent from '../FlashMessageComponent';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

// Override css datetime picker
const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#FE7820',
        },
      },
      MuiPickersDay: {
        day: {
          color: '#FE7820',
        },
        daySelected: {
          backgroundColor: '#FE7820',
          '&:hover': {
            backgroundColor: 'rgba(254,120,32,0.8)',
          }
        },
        dayDisabled: {
          color: '#FE7820',
        },
        current: {
          color: '#FE7820',
        },
      },
      MuiButton: {
        textPrimary: {
          color: '#FE7820',
        }
      },
      MuiPickersClock: {
        pin: {
            backgroundColor: '#FE7820'
        }
      },
      MuiPickersClockPointer: {
        pointer: {
            color: '#FE7820',
            backgroundColor: '#FE7820'
        },
        noPoint: {
            backgroundColor: '#FE7820'
        },
        thumb: {
            border: '14px solid #FE7820'
        }
      },
      MuiPickerDTTabs: {
          tabs: {
              backgroundColor: '#FE7820'
          }
      }
    },
  });


const DatePickerRecipeComponent = ({isPicker, recipe_id, _toggleDatePickerRecipe}) => {

    const clientDatePicker = new ClientDatePicker()
    const [schedules, setSchedules] = useState({
        title: "",
        schedules: []
    })
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState({
        status: false,
        type: ""
    })
    const [isRemove, setIsRemove] = useState({
        status: false,
        schedule_id: ""
    })
    const [isOpenDatePicker, setIsOpenDatePicker] = useState(false)
    const [isFlashMessage, setIsFlashMessage] = useState({
        status: false,
        data: {
            alert: "",
            desc: ""
        }
    })

    // retrieve schedules
    useEffect(() => {
        if (isPicker) {
            setIsLoading(true)
            const fetchSchedules = async () => {
                const data = await clientDatePicker.getSchedules(recipe_id)
                if (data.status === 200) {
                    let datas = await data.json();
                    setSchedules({
                        title: datas.title_recipe,
                        schedules: datas.schedule
                    })
                    setIsLoading(false)
                } else {
                    setIsError({
                        status: true,
                        type: "FETCH_SCHEDULE"
                    })
                }
            }
            fetchSchedules()
        }
    }, [isPicker])

    const _closeDialog = () => {
        _toggleDatePickerRecipe()
    }

    const _removeSchedule = (schedule_id, index) => {

        async function removeSchedule(){

            setIsRemove({
                status: true,
                schedule_id
            });

            const data = await clientDatePicker.removeSchedule(schedule_id)
            if (data.status === 200) {
                const result = schedules['schedules'].filter((s, indexF) => (
                    indexF !== index
                ))
                setSchedules({
                    ...schedules,
                    schedules: result
                })
                setIsRemove({
                    status: false,
                    schedule_id: ""
                })

                setIsFlashMessage({
                    status: true,
                    data: {
                        alert: "SUCCESS",
                        desc: "SUCCESS_REMOVE_SCHEDULE"
                    }
                })

            } else {
                setIsFlashMessage({
                    status: true,
                    data: {
                        alert: "ERROR",
                        desc: "ERROR_REMOVE_SCHEDULE"
                    }
                })
            }

        }
        removeSchedule()
        
    }

    const _openDatePicker = () => {
        if (isOpenDatePicker) {
            setIsOpenDatePicker(!isOpenDatePicker)
        } else {
            setIsOpenDatePicker(!isOpenDatePicker)
        }
        
    }

    // on valide les dates et heures
    const _selectedScheduleDatePicker = (dateTime) => {
        async function setSchedule() {
            const data = await clientDatePicker.setSchedule({id_recipe: recipe_id, schedule: dateTime});
            if (data.status === 200) {
                let datas = await data.json();
                
                const newState = schedules['schedules'].concat([{"id": datas.data.id, "datetime": datas.data.datetime}])

                // sort by datetime
                newState.sort(function(a,b){
                    return new Date(a.datetime) - new Date(b.datetime)
                })

                setSchedules({
                    ...schedules,
                    schedules: newState
                })

                setIsFlashMessage({
                    status: true,
                    data: {
                        alert: "SUCCESS",
                        desc: "SUCCESS_ADD_SCHEDULE"
                    }
                })
        
            } else {
                setIsError({
                    status: true,
                    type: "ERROR_ADD_SCHEDULE"
                })
            }
        }
        setSchedule()
    }

    const _handleCloseFlashMessage = () => {
        setIsFlashMessage({
            status: false,
            data: {
                alert: "",
                desc: ""
            }
        })
    }

    return (
        <>
            {/* DISPLAY LIST SCHEDULES */}
            <DialogDatePickerView 
                isPicker={isPicker}
                title={schedules['title']}
                schedules={schedules['schedules']} 
                isLoading={isLoading}
                isError={isError}
                isRemove={isRemove}
                _closeDialog={_closeDialog}
                _removeSchedule={_removeSchedule}
                _openDatePicker={_openDatePicker}
           />

            {/* DISPLAY DATE TIME PICKER */}
            <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <DateTimePicker 
                        onChange={_selectedScheduleDatePicker}
                        ampm={false}
                        open={isOpenDatePicker}
                        onClose={_openDatePicker}
                        TextFieldComponent={() => null}
                        okLabel="Valider"
                        cancelLabel="Annuler"
                    />
                </MuiPickersUtilsProvider>
            </ThemeProvider>

            {/* DISPLAY FLASH MESSAGE*/}
            <FlashMessageComponent 
                open={isFlashMessage.status} 
                duration={4000} 
                _handleCloseFlashMessage={_handleCloseFlashMessage}
                content={isFlashMessage.data}
            />

        </>

    );
}

export default DatePickerRecipeComponent;