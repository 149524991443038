import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: theme.spacing(2),
        padding: '15px 0',
        flex: 1,
    },
    content: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    button: {
        padding: '0 15px'
    },
    buttonReturn: {
        marginTop: '20px'
    },
    itemDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    removeItem: {
        color: 'rgb(158, 158, 158)',
    },
  }));

const CourseView = (props) => {
    const classes = useStyles();

    let items = props.items;
    
    return (
        <div>
            <Typography variant="h6" className={classes.title}>
                {props.title}
            </Typography>
            <div className={classes.content}>
                {items.map((it,index) => 
                    <div key={index} className={classes.itemDiv}>
                        <Typography variant="body1" className={classes.titleItem}>{it.item}</Typography>
                        <IconButton
                            aria-label="remove item"
                            onClick={() => props._removeItem(index)}  
                        >
                            <DeleteForeverIcon className={classes.removeItem} />
                        </IconButton>
                        
                    </div>
                )}
            </div>
            <div className={classes.button}>
                <Button 
                    fullWidth 
                    variant="contained" 
                    onClick={props._backListCourse}
                    className={classes.buttonReturn}
                    color="primary"
                    disableElevation
                >
                    Retour
                </Button>
            </div>
            
        </div>
    );
}

export default CourseView;