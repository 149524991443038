import React from 'react';
import TextField from '@material-ui/core/TextField';

const LinkView = ({ _handleChange, linkRecipe }) => {
    return (
        <div>
            <TextField 
                required
                fullWidth
                id="link-recipe" 
                label="Lien de la recette" 
                variant="outlined"
                helperText="Url du site (de votre recette)"
                onChange={_handleChange}
                value={linkRecipe}
            />
        </div>
    )
}

export default LinkView;