import React, { Component } from 'react';
import Client from '../../../Client';
import { connect } from 'react-redux';
import { removeCart, removeIdCourse } from '../../../redux/actions/cart';
import ListCourseRecipeView from '../view/ListCourseRecipeView';
import CourseView from '../view/CourseView';
import DialogError from '../../ErrorAuthAPI/view/DialogError';
//import Loading from '../../Loader';

class ModalCourseRecipe extends Component {

    constructor(props){
        super(props);

        this.state = {
            recipes: [],
            items: [],
            theme: "",
            title: "",
            isLoading: true,
            isLoadingViewCourse: false,
            isLoadingDeleteCourse: false,
            id_course: "",
			errorAuthAPI: {
                status: false,
                code_status: "",
                data: {}
			}
        }

        this.client = new Client();
    }

    componentDidMount = () => {
        /* retrieve list course */
        this.client.getCourses().then((res) => {
            let recipes = res.data;
            this.setState({
                ...this.state,
                recipes: this.state.recipes.concat(recipes),
                isLoading: false
            })
        }).catch(error =>  {
			this.setState({
				errorAuthAPI: {
					status: true,
					code_status: error.response.status,
					data: error.response.data
				}
			});
		});
    }

    /*
    * delete a specific list
    */
   _deleteCourseRecipe = (id_course, index) => {

        this.setState({
            ...this.state,
            isLoadingDeleteCourse: true,
            id_course
        });

        this.client.deleteCourse(id_course).then((res) => {
            if( res.data.error === 0 ) {

                let recipe = this.state.recipes;
                recipe.splice(index, 1);

                if( recipe.length > 0 ) {
                    this.setState({
                        ...this.state,
                        recipes: recipe
                    });
                    // -1 in cart
                    // for icon cart in topbar
                    this.props.dispatch(removeCart(1));
                    // add plus cart for recipe view
                    this.props.dispatch(removeIdCourse(id_course));
                }else {
                    // close modal because no one list course
                    this.props._toggleModal();
                    // -1 in cart
                    // for icon cart in topbar
                    this.props.dispatch(removeCart(1));
                    // add plus cart for recipe view
                    this.props.dispatch(removeIdCourse(id_course));
                }
                
            }
        }).catch(error =>  {
			this.setState({
				errorAuthAPI: {
					status: true,
					code_status: error.response.status,
					data: error.response.data
				}
			});
		});
        
    }

    _showCourseRecipe = (id_course) => {

        this.setState({
            ...this.state,
            isLoadingViewCourse: true,
            id_course
        });


        this.client.getCourse(id_course).then((res) => {
            let course = res.data;
            this.setState({
                ...this.state,
                items: this.state.items.concat(course.items),
                theme: course.theme,
                title: course.title,
                isLoadingViewCourse: false,
            })
        }).catch(error =>  {
			this.setState({
				errorAuthAPI: {
					status: true,
					code_status: error.response.status,
					data: error.response.data
				}
			});
		});
        
    }

    _backListCourse = () => {
        this.setState({
            ...this.state,
            items: [],
            theme: "",
            title: ""
        })
    }

    _removeItem = (indexItem) => {
        let copyStateItem = Object.assign([], this.state.items);
        copyStateItem.splice(indexItem, 1);
        this.setState({
            ...this.state,
            items: copyStateItem
        });

        let data = {
            items: copyStateItem
        }

        this.client.updateItemFromCourse(data, this.state.id_course).then(res => {
            
        }).catch(error => {
          this.setState({
              errorAuthAPI: {
                  status: true,
                  code_status: error.response.status,
                  data: error.response.data
              }
          });
        })

    }

    render(){
        if (this.state.errorAuthAPI.status) {
            return <DialogError error={this.state.errorAuthAPI} />
        }else if( this.state.isLoading ) {
            return (
                null
            );
        } else {
            if( this.state.items.length > 0 ) {
                return (
                    <CourseView
                        _toggleModal={this.props._toggleModal}
                        items={this.state.items}
                        theme={this.state.theme}
                        title={this.state.title}
                        _backListCourse={this._backListCourse}
                        _removeItem={this._removeItem}
                    />
                );
            }else {
                return (
                        <ListCourseRecipeView 
                            _toggleModal={this.props._toggleModal}
                            _deleteCourseRecipe={this._deleteCourseRecipe}
                            _showCourseRecipe={this._showCourseRecipe}
                            recipes={this.state.recipes}
                            isLoadingViewCourse={this.state.isLoadingViewCourse}
                            isLoadingDeleteCourse={this.state.isLoadingDeleteCourse}
                            idCourse={this.state.idCourse}
                        />
    
                );
            }
        }


    }
}

ModalCourseRecipe = connect()(ModalCourseRecipe);

export default ModalCourseRecipe;